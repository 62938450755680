export const ORGANISATION_RESET = 'ORGANISATION_RESET';
export const ORGANISATION_COACHES_RESET = 'ORGANISATION_COACH_RESET';
export const ORGANISATION_GROUPS_RESET = 'ORGANISATION_GROUP_RESET';

export const ORGANISATIONS_GET_REQUEST = '@@page/ORGANISATIONS_GET_REQUEST';
export const ORGANISATIONS_GET_SUCCESS = '@@page/ORGANISATIONS_GET_SUCCESS';
export const ORGANISATIONS_GET_FAILURE = '@@page/ORGANISATIONS_GET_FAILURE';
export const ORGANISATIONS_GET_RSAA = [ORGANISATIONS_GET_REQUEST, ORGANISATIONS_GET_SUCCESS, ORGANISATIONS_GET_FAILURE];

export const COACH_POST_REQUEST = '@@page/COACH_POST_REQUEST';
export const COACH_POST_SUCCESS = '@@page/COACH_POST_SUCCESS';
export const COACH_POST_FAILURE = '@@page/COACH_POST_FAILURE';
export const COACH_POST_RSAA = [COACH_POST_REQUEST, COACH_POST_SUCCESS, COACH_POST_FAILURE];

export const COACHES_GET_REQUEST = '@@page/COACHES_GET_REQUEST';
export const COACHES_GET_SUCCESS = '@@page/COACHES_GET_SUCCESS';
export const COACHES_GET_FAILURE = '@@page/COACHES_GET_FAILURE';
export const COACHES_GET_RSAA = [COACHES_GET_REQUEST, COACHES_GET_SUCCESS, COACHES_GET_FAILURE];

export const COACHES_ORGANISATIONS_GET_REQUEST = '@@page/COACHES_ORGANISATIONS_GET_REQUEST';
export const COACHES_ORGANISATIONS_GET_SUCCESS = '@@page/COACHES_ORGANISATIONS_GET_SUCCESS';
export const COACHES_ORGANISATIONS_GET_FAILURE = '@@page/COACHES_ORGANISATIONS_GET_FAILURE';

export const GROUP_POST_REQUEST = '@@page/GROUP_POST_REQUEST';
export const GROUP_POST_SUCCESS = '@@page/GROUP_POST_SUCCESS';
export const GROUP_POST_FAILURE = '@@page/GROUP_POST_FAILURE';
export const GROUP_POST_RSAA = [GROUP_POST_REQUEST, GROUP_POST_SUCCESS, GROUP_POST_FAILURE];

export const GROUPS_GET_REQUEST = '@@page/GROUPS_GET_REQUEST';
export const GROUPS_GET_SUCCESS = '@@page/GROUPS_GET_SUCCESS';
export const GROUPS_GET_FAILURE = '@@page/GROUPS_GET_FAILURE';
export const GROUPS_GET_RSAA = [GROUPS_GET_REQUEST, GROUPS_GET_SUCCESS, GROUPS_GET_FAILURE];

export const GROUPS_ORGANISATIONS_REQUEST = '@@page/GROUPS_ORGANISATIONS_REQUEST';
export const GROUPS_ORGANISATIONS_GET_SUCCESS = '@@page/GROUPS_ORGANISATIONS_GET_SUCCESS';
export const GROUPS_ORGANISATIONS_GET_FAILURE = '@@page/GROUPS_ORGANISATIONS_GET_FAILURE';

export const STUDENT_GET_REQUEST = '@@page/STUDENT_GET_REQUEST';
export const STUDENT_GET_SUCCESS = '@@page/STUDENT_GET_SUCCESS';
export const STUDENT_GET_FAILURE = '@@page/STUDENT_GET_FAILURE';
export const STUDENT_GET_RSAA = [STUDENT_GET_REQUEST, STUDENT_GET_SUCCESS, STUDENT_GET_FAILURE];

export const STUDENT_ANSWERS_GET_REQUEST = '@@page/STUDENT_ANSWERS_GET_REQUEST';
export const STUDENT_ANSWERS_GET_SUCCESS = '@@page/STUDENT_ANSWERS__GET_SUCCESS';
export const STUDENT_ANSWERS_GET_FAILURE = '@@page/STUDENT_ANSWERS_GET_FAILURE';
export const STUDENT_ANSWERS_GET_RSAA = [STUDENT_ANSWERS_GET_REQUEST, STUDENT_ANSWERS_GET_SUCCESS, STUDENT_ANSWERS_GET_FAILURE];

export const STUDENT_DOWNLOAD_ANSWER_GET_REQUEST = '@@page/STUDENT_DOWNLOAD_ANSWER_GET_REQUEST';
export const STUDENT_DOWNLOAD_ANSWER_GET_SUCCESS = '@@page/STUDENT_DOWNLOAD_ANSWER_GET_SUCCESS';
export const STUDENT_DOWNLOAD_ANSWER_GET_FAILURE = '@@page/STUDENT_DOWNLOAD_ANSWER_GET_FAILURE';
export const STUDENT_DOWNLOAD_ANSWER_GET_RSAA = [STUDENT_DOWNLOAD_ANSWER_GET_REQUEST, STUDENT_DOWNLOAD_ANSWER_GET_SUCCESS, STUDENT_DOWNLOAD_ANSWER_GET_FAILURE];

export const STUDENT_DOWNLOAD_ANSWER_RESET = '@@page/STUDENT_DOWNLOAD_ANSWER_RESET';

export const STUDENT_DELETE_ANSWER_POST_REQUEST = '@@page/STUDENT_DELETE_ANSWER_POST_REQUEST';
export const STUDENT_DELETE_ANSWER_POST_SUCCESS = '@@page/STUDENT_DELETE_ANSWER_POST_SUCCESS';
export const STUDENT_DELETE_ANSWER_POST_FAILURE = '@@page/STUDENT_DELETE_ANSWER_POST_FAILURE';
export const STUDENT_DELETE_ANSWER_POST_RSAA = [STUDENT_DELETE_ANSWER_POST_REQUEST, STUDENT_DELETE_ANSWER_POST_SUCCESS, STUDENT_DELETE_ANSWER_POST_FAILURE];

export const STUDENT_ADDINFO_GET_REQUEST = '@@page/STUDENT_ADDINFO_GET_REQUEST';
export const STUDENT_ADDINFO_GET_SUCCESS = '@@page/STUDENT_ADDINFO_GET_SUCCESS';
export const STUDENT_ADDINFO_GET_FAILURE = '@@page/STUDENT_ADDINFO_GET_FAILURE';
export const STUDENT_ADDINFO_GET_RSAA = [STUDENT_ADDINFO_GET_REQUEST, STUDENT_ADDINFO_GET_SUCCESS, STUDENT_ADDINFO_GET_FAILURE];

export const STUDENTS_PROGRESS_GET_REQUEST = '@@page/STUDENTS_PROGRESS_GET_REQUEST';
export const STUDENTS_PROGRESS_GET_SUCCESS = '@@page/STUDENTS_PROGRESS_GET_SUCCESS';
export const STUDENTS_PROGRESS_GET_FAILURE = '@@page/STUDENTS_PROGRESS_GET_FAILURE';
export const STUDENTS_PROGRESS_GET_RSAA = [STUDENTS_PROGRESS_GET_REQUEST, STUDENTS_PROGRESS_GET_SUCCESS, STUDENTS_PROGRESS_GET_FAILURE];

export const STUDENTS_SEARCH_GET_REQUEST = '@@page/STUDENTS_SEARCH_GET_REQUEST';
export const STUDENTS_SEARCH_GET_SUCCESS = '@@page/STUDENTS_SEARCH_GET_SUCCESS';
export const STUDENTS_SEARCH_GET_FAILURE = '@@page/STUDENTS_SEARCH_GET_FAILURE';
export const STUDENTS_SEARCH_GET_RSAA = [STUDENTS_SEARCH_GET_REQUEST, STUDENTS_SEARCH_GET_SUCCESS, STUDENTS_SEARCH_GET_FAILURE];

export const VOUCHERS_GET_REQUEST = '@@page/VOUCHERS_GET_REQUEST';
export const VOUCHERS_GET_SUCCESS = '@@page/VOUCHERS_GET_SUCCESS';
export const VOUCHERS_GET_FAILURE = '@@page/VOUCHERS_GET_FAILURE';
export const VOUCHERS_GET_RSAA = [VOUCHERS_GET_REQUEST, VOUCHERS_GET_SUCCESS, VOUCHERS_GET_FAILURE];

export const VOUCHERS_VARIANT_POST_REQUEST = '@@page/VOUCHERS_VARIANT_POST_REQUEST';
export const VOUCHERS_VARIANT_POST_SUCCESS = '@@page/VOUCHERS_VARIANT_POST_SUCCESS';
export const VOUCHERS_VARIANT_POST_FAILURE = '@@page/VOUCHERS_VARIANT_POST_FAILURE';
export const VOUCHERS_VARIANT_POST_RSAA = [VOUCHERS_VARIANT_POST_REQUEST, VOUCHERS_VARIANT_POST_SUCCESS, VOUCHERS_VARIANT_POST_FAILURE];