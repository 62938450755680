import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import { createApiMiddleware } from './middleware';
import rootReducer from './reducers';

const apiMiddleware = createApiMiddleware();
export default function configureStore(state) {
    if (state) {
        return createStore(
            rootReducer,
            state,
            applyMiddleware(
                apiMiddleware,
                thunk
            )
        );
    }
    return createStore(
        rootReducer,
        applyMiddleware(
            apiMiddleware,
            thunk
        )
    );
}