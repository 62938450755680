import React, {Component} from "react";
import '../../static/styles/cookie-notice.scss';
import {Link} from 'react-router-dom';

class CookieNotice extends Component {
    
    constructor() {
        super();
        
        this.state = {
            cookieconsent: localStorage.getItem('cookieconsent')
        }    
    }
    
    acceptCookies = () => {
        
        localStorage.setItem('cookieconsent', 'accept')
        this.setState({ cookieconsent: 'accept' });
    }
    
    render() {
        
        if( this.state.cookieconsent === 'accept' ){
            return false;
        }

        return (
            <div id="cookie-notice">
                Om ervoor te zorgen dat onze website goed werkt gebruiken wij cookies. Lees ook onze <Link to="/privacy/">privacyverklaring</Link>. <button className="button" onClick={this.acceptCookies}>Oké</button>
            </div>
        );
    }
}

export default CookieNotice;