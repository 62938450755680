import {apiMiddleware, isRSAA} from 'redux-api-middleware';
import {refreshAccessToken, logout} from './actions/auth'
import {TOKEN_RECEIVED} from "./constants/auth";
import {refreshToken, isAccessTokenExpired, isRefreshTokenExpired} from "./reducers/auth";

export function createApiMiddleware() {
    let postponedRSAAs = [];

    return ({dispatch, getState}) => {
        const rsaaMiddleware = apiMiddleware({dispatch, getState});

        return (next) => (action) => {

            const nextCheckPostponed = (nextAction) => {
                // Run postponed actions after token refresh
                if (nextAction.type === TOKEN_RECEIVED) {
                    next(nextAction);
                    postponedRSAAs.forEach((postponed) => {
                        rsaaMiddleware(next)(postponed)
                    });
                    postponedRSAAs.length = 0
                } else {
                    next(nextAction)
                }
            };

            if (isRSAA(action)) {
                const state = getState(), token = refreshToken(state.auth);
                // Expired refresh = logout
                if (token && isRefreshTokenExpired(state.auth)) {
                    return rsaaMiddleware(nextCheckPostponed)(logout())
                // Expired access = obtain new refresh
                } else if (token && isAccessTokenExpired(state.auth)) {
                    // Don't forget the RSAAs
                    postponedRSAAs.push(action);
                    if (postponedRSAAs.length === 1) {
                        return rsaaMiddleware(nextCheckPostponed)(refreshAccessToken(token))
                    } else {
                        return
                    }
                }

                return rsaaMiddleware(next)(action);
            }
            return next(action);
        }
    }
}

export default createApiMiddleware();