import jwtDecode from 'jwt-decode';
import {
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    SIGNUP_FAILURE,
    SIGNUP_SUCCESS, TEMP_TOKEN_RECEIVED,
    TOKEN_FAILURE,
    TOKEN_RECEIVED, VERIFY_TOKEN_FAILURE,
    RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, CLEAR_AUTH,
    UPDATE_PROFILE_PICTURE
} from "../constants/auth";
import {VOUCHER_POST_FAILURE, VOUCHER_POST_SUCCESS} from "../constants/voucher";

const initialState = {
    email: undefined,  // This for registering, it has to be non-persistent.
    tokens: {
        access: undefined,
        refresh: undefined,
        email: undefined,
        first_name: undefined,
        last_name: undefined,
        full_name: undefined,
        is_coach: undefined,
        coach_organisations: undefined,
        picture: undefined,
        valid_voucher: undefined,
    },
    readspeaker_token: undefined,
    errors: {},
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:

            let skipVideo = localStorage.getItem('skipVideo');
            if (skipVideo !== null) {
                localStorage.setItem('skipVideo', (parseInt(skipVideo) + 1));
            }

            return {
                tokens: {
                    email: action.payload.email,
                    first_name: action.payload.first_name,
                    last_name: action.payload.last_name,
                    full_name: action.payload.first_name + " " + action.payload.last_name,
                    is_coach: action.payload.is_coach,
                    coach_organisations: action.payload.coach_organisations,
                    picture: action.payload.picture,
                    access: {
                        token: action.payload.access,
                        ...jwtDecode(action.payload.access)
                    },
                    refresh: {
                        token: action.payload.refresh,
                        ...jwtDecode(action.payload.refresh)
                    },
                    valid_voucher: action.payload.valid_voucher
                },
                errors: {}
            };
        case TOKEN_RECEIVED:
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    access: {
                        token: action.payload.access,
                        ...jwtDecode(action.payload.access)
                    },
                    refresh: {
                        token: action.payload.refresh,
                        ...jwtDecode(action.payload.refresh)
                    },
                }
            };
        case TEMP_TOKEN_RECEIVED:
            return {
                ...state,
                readspeaker_token: action.payload.access
            }
        case VOUCHER_POST_SUCCESS:
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    valid_voucher: action.payload.valid_voucher,
                },
                errors: {},
            };
        case VOUCHER_POST_FAILURE:
            return {
                ...state,
                errors:
                    action.payload.response ||
                    {'non_field_errors': action.payload.statusText},
            };
        case LOGIN_FAILURE:
            return {
                errors:
                    action.payload.response ||
                    {'non_field_errors': action.payload.statusText},
            };
        case SIGNUP_SUCCESS:
            return {
                email: action.payload.email,
                errors: {}
            };
        case SIGNUP_FAILURE:
            return {
                errors:
                    action.payload.response ||
                    {'non_field_errors': action.payload.statusText},
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                resetPassword: true,
                message: action.payload.message,
                errors: {}
            };
        case RESET_PASSWORD_FAILURE:
            return {
                resetPassword: true,
                errors:
                    action.payload.response ||
                    {'non_field_errors': action.payload.statusText},
            };
        case UPDATE_PROFILE_PICTURE:
            console.log('Updating profile picture to: ', action.image_path)
            console.log('New state: ', {
                ...state,
                tokens: {
                    ...state.tokens,
                    picture: action.image_path,
                }
            })
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    picture: action.image_path,
                }
            };
        case CLEAR_AUTH:
            return {
                email: undefined,  // This for registering, it has to be non-persistent.
                tokens: {
                    access: undefined,
                    refresh: undefined,
                    email: undefined,
                    first_name: undefined,
                    last_name: undefined,
                    full_name: undefined,
                    is_coach: undefined,
                    coach_organisations: undefined,
                    picture: undefined,
                    valid_voucher: undefined,
                    resetPassword: undefined,
                    message: undefined,
                },
                readspeaker_token: undefined,
                errors: {},
            };
        case TOKEN_FAILURE:
        case VERIFY_TOKEN_FAILURE:
        case LOGOUT:
            return {
                errors: {}
            };

        default:
            return state
    }
};

export default AuthReducer;

// Used for auth header
export function accessToken(state) {
    if (state.tokens && state.tokens.access) {
        return state.tokens.access.token
    }
}

// Required for middleware.js
export function refreshToken(state) {
    if (state.tokens && state.tokens.refresh) {
        return state.tokens.refresh.token
    }
}

// Required for middleware.js
export function isAccessTokenExpired(state) {
    if (state.tokens && state.tokens.access && state.tokens.access.exp) {
        return 1000 * state.tokens.access.exp - (new Date()).getTime() < 10000
    }
    return true
}

// Use to check if authenticated
export function isRefreshTokenExpired(state) {
    if (state.tokens && state.tokens.refresh && state.tokens.refresh.exp) {
        return 1000 * state.tokens.refresh.exp - (new Date()).getTime() < 10000
    }
    return true
}

// Use to check if authenticated
export function isAuthenticated(state) {
    return !isRefreshTokenExpired(state)
}


