import React, {Component} from "react";
import loading from '../../static/images/loading.svg';

class Loading extends Component {
    
    render() {
        
        return (
            <div className="loading-container min-height-header-footer">
                <div className="loading-inner">
                    <span>Laden...</span>
                    <img src={loading} alt="loading" className="loading" />
                </div>
            </div>
        );
    }
}


export default Loading;