import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import React, {Component, lazy, Suspense} from "react";

import connect from "react-redux/es/connect/connect";
import {isAuthenticated} from "../reducers/auth";
import Loading from "./Inc/Loading";
import {verifyAccessToken} from "../actions/auth";
import queryString from 'query-string';

const Intro = lazy(() => import('./Intro'));
const Courses = lazy(() => import('./Courses'));
const Voucher = lazy(() => import('./Voucher'));
const Page = lazy(() => import('./Page'));
const MediaLibrary = lazy(() => import('./MediaLibrary'));
const Concepts = lazy(() => import('./Concepts'));
const Studenten = lazy(() => import('./Studenten'));
const Search = lazy(() => import('./Search'));
const Profile = lazy(() => import('./Profile'));
const StudentAnswers = lazy(() => import('./Studenten/StudentAnswers'));
const User = lazy(() => import('./User'));


class Content extends Component {

    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.props.verifyToken(this.props.tokens.access.token);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {

        if (this.props.isAuthenticated) {
            if (this.props.tokens.valid_voucher !== true) {
                return (
                    <Suspense fallback={<Loading />}>
                        {this.props.tokens.is_coach ? (
                            <Switch>
                                <Route exact path='/login/' render={() => <Redirect to={"/profile/"}/>}/>
                                {/*<Route exact path='/registreer/' render={() => <Redirect to={"/profile/"}/>}/>*/}
                                <Route exact path='/opleiding/' render={() => <Redirect to={"/profile/"}/>}/>
                                <Route exact path='/' render={() => <Redirect to={"/voucher/"}/>}/>
                                <Route path='/voucher/' component={Voucher}/>
                                <Route path='/studenten/' component={Studenten}/>
                                <Route exact path='/profile/' component={Profile}/>
                                <Route exact path='/search/' component={Search}/>
                            </Switch>
                        ) : (
                            <Switch>
                                <Route exact path='/login/' render={() => <Redirect to={"/voucher/"}/>}/>
                                {/*<Route exact path='/registreer/' render={() => <Redirect to={"/voucher/"}/>}/>*/}
                                <Route exact path='/opleiding/' render={() => <Redirect to={"/voucher/"}/>}/>
                                <Route exact path='/' render={() => <Redirect to={"/voucher/"}/>}/>
                                <Route path='/voucher/' component={Voucher}/>
                            </Switch>
                        )}
                    </Suspense>
                )
            } else {
                let default_slug = '/opleiding/';

                let params = queryString.parse(this.props.location.search)
                if(params.next !== undefined){
                  default_slug = params.next;
                } else {

                  if (this.props.current_course.url !== undefined) {

                      default_slug = this.props.current_course.url;
                      let skipVideo = localStorage.getItem('skipVideo');

                      if (this.props.current_course.video && (skipVideo === null || parseInt(skipVideo) >= 10 )) {
                          default_slug = default_slug + 'video/'

                          if( skipVideo !== null ) {
                              localStorage.removeItem('skipVideo');
                          }
                      }
                  }
                }

                return (
                    <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route exact path='/:slug/video/' component={Intro}/>
                        <Route exact path='/search/' component={Search}/>
                        <Route exact path='/profile/' component={Profile}/>
                        <Route exact path='/login/' render={() => <Redirect to={default_slug}/>}/>
                        <Route exact path='/begrippen/' component={Concepts}/>
                        <Route exact path='/opleiding/' component={Courses}/>
                        <Route exact path='/mediatheek/' component={MediaLibrary}/>
                        <Route exact path='/begrippen/:letter([a-z])/' component={Concepts}/>
                        <Route exact path='/' render={() => <Redirect to={default_slug} />}/>
                        <Route exact path='/voucher/' render={() => <Redirect to={default_slug} />}/>
                        {this.props.tokens.is_coach ? <Route path='/studenten/' component={Studenten}/> : ''}
                        {!this.props.tokens.is_coach ? <Route exact path='/studenten/profiel/:id/antwoorden/:course_id/' component={StudentAnswers}/> : ''}
                        <Route path='/preview/' component={Page}/>
                        <Route path='/' render={() => <React.Fragment><Page/></React.Fragment>}/>
                    </Switch>
                    </Suspense>
                )
            }
        } else {

            return (
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route path='/login/' render={() => <User userPage='login' />} />
                        {/*<Route path='/registreer/' render={() => <User userPage='register' />} />*/}
                        {/*<Route path='/reset-password/' render={() => <User userPage='reset-password' />} />*/}
                        {/*<Route exact path='/' render={() => <Redirect to={"/login/"}/>}/>*/}
                        <Route path='/' render={() => <Redirect to={"/login/"}/>}/>
                    </Switch>
                </Suspense>
            )
        }
    }
}


function mapStateToProps(state) {
    return {
        isAuthenticated: isAuthenticated(state.auth),
        tokens: state.auth.tokens,
        current_course: state.page.current_course
    };
}

const mapDispatchToProps = (dispatch) => ({
    verifyToken: (token) => {
        dispatch(verifyAccessToken(token))
    },
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
