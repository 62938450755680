import React from "react";
import {Link} from 'react-router-dom';
import logo from "../../static/images/tectum.svg";

class Footer extends React.Component {

    render() {

        return (
            <footer id="bottom">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 footer-column">
                            <div className="footer-copyright">Copyright {new Date().getFullYear()} - Tectum / <Link to="/algemene-voorwaarden/">Algemene voorwaarden</Link> / <Link to="/disclaimer/">Disclaimer</Link> / Ontwerp: <a href="https://www.brandiacs.nl/" target="_blank" rel="noopener noreferrer">Brandiacs</a> / Bouw: <a href="https://go2people-websites.nl/" target="_blank" rel="noopener noreferrer">Go2People Websites</a></div>
                            <a href="https://www.tectum.nl/" className="footer-logo" target="_blank" rel="noopener noreferrer"><img src={logo} alt="logo" /></a>
                        </div>
                    </div>
                </div>
            </footer>
        )

    }
}

export default Footer;
