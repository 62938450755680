import {combineReducers} from 'redux';
import AuthReducer from './auth';
import {accessToken} from './auth';
import PageReducer from './page';
import OrganisationReducer from './organisation';
import ConceptReducer from './concept';
import VoucherReducer from './voucher';
import MediaLibraryReducer from './medialibrary';


const rootReducer = combineReducers({
    auth: AuthReducer,
    page: PageReducer,
    organisation: OrganisationReducer,
    concept: ConceptReducer,
    voucher: VoucherReducer,
    media: MediaLibraryReducer,
});

export default rootReducer;

export function withAuth(headers = {}) {
    return (state) => {
        let accesstoken = accessToken(state.auth)
        if (accesstoken === undefined) {
            return {...headers,}
        }
        return {
        ...headers,
        'Authorization': `Bearer ${accesstoken}`
    }}
}