import React from "react";
import {withRouter, Link} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {isAuthenticated} from "../../reducers/auth";
import {logout} from "../../actions/auth";
import Menu from "./Menu"
import SearchForm from "./SearchForm"
import logo from '../../static/images/tectum.svg';
import {resetMenu} from "../../actions/page";
import {resetOrganisation} from "../../actions/organisation";


class Header extends React.Component {

    state = {
        searchForm: false,
    }

    toggleSearchForm = () => {
        this.setState({ searchForm: !this.state.searchForm })
    }

    render() {
        let header = '';
        if( this.props.logged_in ){

            header = (
                <React.Fragment>
                    <div className="main-title-container">
                        <Link to={this.props.current_course.url ? this.props.current_course.url : "/"} className="main-title">
                            {this.props.location.pathname.startsWith('/studenten/') ? (
                                <React.Fragment>
                                    <span>Mijn</span>
                                    Studenten
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <span>{ this.props.current_course.subtitle ? this.props.current_course.subtitle : "Leeromgeving" }</span>
                                    { this.props.current_course.title ? this.props.current_course.title : "Tectumleren" }
                                </React.Fragment>
                            )}

                        </Link>
                    </div>
                    <div className="nav-container">

                        <div className="menu-button">
                            <span className="button-text">Menu</span>
                            <div id="nav-toggle"><span></span></div>
                        </div>

                        <nav className="header-nav">
                            <Menu menu="header"
                                  name={this.props.first_name}
                                  logout={this.props.logout}
                                  is_coach={this.props.is_coach}
                                  picture={this.props.picture}
                                  current_course={this.props.current_course}
                                  valid_voucher={this.props.valid_voucher}
                                  toggle_search_form={this.toggleSearchForm}
                            />
                        </nav>
                        <nav className="off-canvas-nav">
                            <Menu menu="mobilenav"
                                  name={this.props.first_name}
                                  logout={this.props.logout}
                                  is_coach={this.props.is_coach}
                                  picture={this.props.picture}
                                  current_course={this.props.current_course}
                                  valid_voucher={this.props.valid_voucher}
                            />
                        </nav>
                    </div>
                </React.Fragment>
            );
        } else {

            header = (
                <React.Fragment>
                    <div className="main-title-container">
                        <Link to={'/login/'} className="main-title">
                            <span>Tectum leren</span>
                            Online leeromgeving
                        </Link>
                    </div>
                    <div className="logo-container">
                        <a href="https://tectum.nl" target="_blank" rel="noopener noreferrer"><img src={logo} alt="Tectum voor dakvakmaschap" className="logo" /></a>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <header id="top">
                <SearchForm show={this.state.searchForm} toggle_search_form={this.toggleSearchForm} is_coach={this.props.is_coach} />
                <div className="container">
                    <div className="row">
                        <div className="col-12 header-inner-container">
                            {header}
                        </div>
                    </div>
                </div>
            </header>
        )

    }
}

function mapStateToProps(state) {
    if (state.auth.tokens) {
        return {
            logged_in: isAuthenticated(state.auth),
            first_name: state.auth.tokens.first_name,
            is_coach: state.auth.tokens.is_coach,
            picture: state.auth.tokens.picture,
            current_course: state.page.current_course,
            valid_voucher: state.auth.tokens.valid_voucher,
        };
    }
    return {
        logged_in: isAuthenticated(state.auth),
        name: undefined,
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(resetOrganisation())
        dispatch(resetMenu())
        dispatch(logout())
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
