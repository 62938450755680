import {
    PAGES_GET_SUCCESS,
    PAGE_GET_SUCCESS,
    GET_ANSWER_SUCCESS,
    GET_ANSWERS_SUCCESS,
    POST_ANSWER_SUCCESS,
    POST_ANSWERS_SUCCESS,
    PAGE_GET_FAILURE,
    PAGE_GET_REQUEST,
    FEEDBACK_GET_REQUEST,
    FEEDBACK_GET_SUCCESS,
    FEEDBACK_GET_FAILURE,
    FEEDBACK_POST_SUCCESS,
    FEEDBACK_POST_FAILURE,
    STUDENTS_ANSWER_GET_REQUEST,
    STUDENTS_ANSWER_GET_SUCCESS,
    STUDENTS_ANSWER_GET_FAILURE,
    SUBMIT_EXAM_SUCCESS,
    SUBMIT_EXAM_REQUEST,
    SUBMIT_EXAM_FAILURE,
    GET_ANSWER_FAILURE,
    GET_ANSWERS_FAILURE,
    GET_EXAM_SUCCESS,
    MENU_GET_REQUEST,
    MENU_GET_SUCCESS,
    MENU_RESET,
    SIDE_MENU_GET_REQUEST,
    SIDE_MENU_GET_SUCCESS,
    SIDE_MENU_BLOCK_GET_SUCCESS,
    QUESTION_GET_SUCCESS,
    STUDENTS_DELETE_ANSWER_POST_FAILURE,
    STUDENTS_DELETE_ANSWER_POST_SUCCESS,
    STUDENTS_DELETE_ANSWER_POST_REQUEST,
    RESET_ANSWER, FEEDBACK_POST_REQUEST,
    STUDENTS_EXAM_GET_REQUEST, STUDENTS_EXAM_GET_SUCCESS, STUDENTS_EXAM_GET_FAILURE,
    STUDENTS_EXAM_POST_FAILURE
} from "../constants/page";
import {VOUCHER_POST_SUCCESS} from "../constants/voucher";
import {LOGIN_SUCCESS, TOKEN_RECEIVED, VERIFY_TOKEN_RECEIVED} from "../constants/auth";

const initialState = {
    list: undefined,
    item: undefined,
    answer: undefined,
    errors: {},
    exam: 0,
    exam_url: undefined,
    menu: {},
    side_menu: {},
    side_menu_block: [],
    current_course: {},
    questions: {},
    organisations: undefined,
    coaches: undefined,
    student_answers: undefined,
    student_answers_deleted: undefined,
    feedback_options: undefined,
    feedback_submitted: undefined,
    exam_options: undefined,
};

const PageReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENU_GET_REQUEST:
            return Object.assign({}, state, {
                menu: {},
            });
        case MENU_GET_SUCCESS:
            return Object.assign({}, state, {
                menu: action.payload,
            });
        case MENU_RESET:
            return Object.assign({}, state, {
                menu: {},
            });
        case PAGES_GET_SUCCESS:
            return Object.assign({}, state, {
                list: action.payload.items,
            });
        case VOUCHER_POST_SUCCESS:
            return Object.assign({}, state, {
                list: action.payload.items,
            });
        case PAGE_GET_SUCCESS:
            return Object.assign({}, state, {
                item: action.payload,
                //answer: undefined,
                errors: {},
                current_course: action.payload.current_course,
                side_menu: {},
                side_menu_block: {},
                questions: {},
                student_answers_deleted: undefined
            });
        case VERIFY_TOKEN_RECEIVED:
        case TOKEN_RECEIVED:
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                current_course: action.payload.current_course,
            });
        case PAGE_GET_REQUEST:

            return {
                ...state,
                // item: undefined,
                exam_url: undefined,
                errors: {}
            };
        case SUBMIT_EXAM_REQUEST:
            return Object.assign({}, state, {
                exam: 1
            });
        case SUBMIT_EXAM_SUCCESS:
            return Object.assign({}, state, {
                exam: 2
            });
        case SUBMIT_EXAM_FAILURE:
            return Object.assign({}, state, {
                exam: 3,
                errors: action.payload
            });
        case GET_EXAM_SUCCESS:
            return Object.assign({}, state, {
                exam_url: action.payload.url,
            });
        case QUESTION_GET_SUCCESS:

            let questions = Object.values(state.questions);

            questions.push(action.payload);

            return Object.assign({}, state, {
                questions: questions,
            });
        case POST_ANSWER_SUCCESS:
        case GET_ANSWER_SUCCESS:
            return Object.assign({}, state, {
                answer: action.payload,
            });
        case POST_ANSWERS_SUCCESS: // The same as POST_ANSWER_SUCCESS, except for that it accepts questions for multiple questions
        case GET_ANSWERS_SUCCESS: // The same as GET_ANSWER_SUCCESS, except for that it accepts questions for multiple questions
            let answers;

            if (state.answer !== undefined) {
                answers = Object.values(state.answer);
            } else {
                answers = []
            }

            answers.push(action.payload);

            return Object.assign({}, state, {
                answer: answers,
            });
        case GET_ANSWER_FAILURE:
        case GET_ANSWERS_FAILURE:
            return Object.assign({}, state, {
                answer: false,
            });
        case PAGE_GET_FAILURE:
            return {
                ...state,
                item: undefined,
                errors:
                    action.payload.response ||
                    {'non_field_errors': action.payload.statusText},
            };
        case SIDE_MENU_GET_REQUEST:
            return Object.assign({}, state, {
                side_menu: {}
            });
        case SIDE_MENU_GET_SUCCESS:

            return Object.assign({}, state, {
                side_menu: action.payload,
            });
        case SIDE_MENU_BLOCK_GET_SUCCESS:

            let nextBlocks = Object.values(state.side_menu_block);
            nextBlocks.push(action.payload);

            return Object.assign({}, state, {
                side_menu_block: nextBlocks,
            });

        case STUDENTS_EXAM_GET_REQUEST:
            return Object.assign({}, state, {
                exam_options: undefined,
                errors: undefined,
                //student_answers_deleted: undefined
            });
        case STUDENTS_EXAM_GET_SUCCESS:
            return Object.assign({}, state, {
                exam_options: action.payload,
            });
        case STUDENTS_EXAM_GET_FAILURE:
            return Object.assign({}, state, {
                errors: action.payload
            });
        case STUDENTS_EXAM_POST_FAILURE:
            return Object.assign({}, state, {
                errors: action.payload
            });
        case FEEDBACK_GET_REQUEST:
            return Object.assign({}, state, {
                feedback_options: undefined,
                errors: undefined,
                //student_answers_deleted: undefined
            });
        case FEEDBACK_GET_SUCCESS:
            return Object.assign({}, state, {
                feedback_options: action.payload,
            });
        case FEEDBACK_GET_FAILURE:
            return Object.assign({}, state, {
                errors: action.payload
            });
        case FEEDBACK_POST_REQUEST:
            return Object.assign({}, state, {
                feedback_submitted: undefined,
            });
        case FEEDBACK_POST_SUCCESS:
            return Object.assign({}, state, {
                feedback_options: undefined,
                student_answers: undefined, // Student view, set loading
                feedback_submitted: true,
            });
        case FEEDBACK_POST_FAILURE:
            return Object.assign({}, state, {
                errors: action.payload
            });

        case STUDENTS_ANSWER_GET_REQUEST:
            return Object.assign({}, state, {
                student_answers: undefined,
                //student_answers_deleted: undefined
            });
        case STUDENTS_ANSWER_GET_SUCCESS:
            return Object.assign({}, state, {
                student_answers: action.payload,
            });
        case STUDENTS_ANSWER_GET_FAILURE:
            return Object.assign({}, state, {
                errors: action.payload
            });

        case STUDENTS_DELETE_ANSWER_POST_REQUEST:
            return Object.assign({}, state, {
                student_answers_deleted: undefined
            });

        case STUDENTS_DELETE_ANSWER_POST_SUCCESS:
            return Object.assign({}, state, {
                student_answers: undefined,
                student_answers_deleted: action.payload
            });

        case STUDENTS_DELETE_ANSWER_POST_FAILURE:
            return Object.assign({}, state, {
                errors: action.payload
            });

        case RESET_ANSWER:
            return Object.assign({}, state, {
                answer: undefined
            });

        default:
            return state
    }
}

export default PageReducer;