import {createAction} from "redux-api-middleware";
import {API_URL} from '../constants';

import {withAuth} from "../reducers";
import {
    PAGE_GET_RSAA,
    MENU_RESET,
    MENU_GET_RSAA,
    PAGES_GET_RSAA,
    GET_ANSWER_RSAA,
    GET_ANSWERS_RSAA,
    POST_ANSWER_RSAA,
    POST_ANSWERS_RSAA,
    SUBMIT_EXAM_RSAA,
    GET_EXAM_RSAA,
    SIDE_MENU_GET_RSAA,
    SIDE_MENU_BLOCK_GET_RSAA,
    QUESTION_GET_RSAA,
    FEEDBACK_GET_RSAA, FEEDBACK_POST_RSAA,
    STUDENTS_ANSWER_GET_RSAA,
    STUDENTS_DELETE_ANSWER_POST_RSAA, RESET_ANSWER,
    STUDENTS_EXAM_GET_RSAA, STUDENTS_EXAM_POST_RSAA,
} from "../constants/page";

export const fetchPages = (searchParams) => createAction({
    endpoint: API_URL + 'pages/' + searchParams,
    method: 'GET',
    headers: withAuth(),
    types: PAGES_GET_RSAA
});

export const fetchCourses = () => createAction({
    endpoint: API_URL + 'pages/courses/',
    method: 'GET',
    headers: withAuth(),
    types: PAGES_GET_RSAA
});

export const fetchPage = (urlPath) => createAction({
    endpoint: API_URL + 'pages/with-path' + urlPath,
    method: 'GET',
    credentials: 'omit',
    headers: withAuth(),
    types: PAGE_GET_RSAA
});

export const fetchPreviewPage = (urlPath) => createAction({
    endpoint: API_URL + 'pages_preview' + urlPath,
    method: 'GET',
    credentials: 'omit',
    headers: withAuth(),
    types: PAGE_GET_RSAA
});

export const fetchQuestion = (id) => createAction({
    endpoint: API_URL + 'pages/' + id.toString() + "/",
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: QUESTION_GET_RSAA
});

export const resetAnswer = () => ({
    type: RESET_ANSWER,
})

export const getAnswer = (id) => createAction({
    endpoint: API_URL + 'pages/answer/' + id.toString() + "/",
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: GET_ANSWER_RSAA,
});

export const getAnswers = (id) => createAction({
    endpoint: API_URL + 'pages/answer/' + id.toString() + "/",
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: GET_ANSWERS_RSAA
});

export const postAnswerAPI = (id, data) => createAction({
    endpoint: API_URL + 'pages/answer/' + id.toString() + "/",
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: POST_ANSWER_RSAA
});

export function postAnswer(id, data) {

    return function(dispatch, getState) {
        return dispatch(postAnswerAPI(id, data))
            .then(() => dispatch(fetchMenu()));
    };
}

export const postAnswersAPI = (id, data) => createAction({
    endpoint: API_URL + 'pages/answer/' + id.toString() + "/",
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: POST_ANSWERS_RSAA
});

export function postAnswers(id, data, updateMenu) {

    return function(dispatch, getState) {

        if( !updateMenu ) {
            return dispatch(postAnswersAPI(id, data))
        }

        return dispatch(postAnswersAPI(id, data))
            .then(() => dispatch(fetchMenu()));

    };
}

export const fetchExamOptions = (id) => createAction({
    endpoint: API_URL + 'pages/' + id.toString() + '/open_exam/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENTS_EXAM_GET_RSAA
});

export const postExamOptions = (data) => createAction({
    endpoint: API_URL + 'exams/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENTS_EXAM_POST_RSAA
});

export const fetchFeedbackOptions = (id) => createAction({
    endpoint: API_URL + 'pages/' + id.toString() + '/feedback/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: FEEDBACK_GET_RSAA
});

export const postFeedbackOptions = (data) => createAction({
    endpoint: API_URL + 'feedback/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: FEEDBACK_POST_RSAA
});

export const fetchStudentAnswers = (id) => createAction({
    endpoint: API_URL + 'pages/students/answers/' + id.toString() + '/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENTS_ANSWER_GET_RSAA
});

export const postDeleteAnswers = (data) => createAction({
    endpoint: API_URL + 'students/answers/delete/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENTS_DELETE_ANSWER_POST_RSAA
});

// export function postDeleteAnswers(data) {
//
//     return function(dispatch, getState) {
//
//         return dispatch(postDeleteAnswersAPI(data))
//             //.then(() => dispatch(fetchMenu()));
//
//     };
// }

export const submitExam = (id, data) => createAction({
    endpoint: API_URL + 'pages/submit/' + id.toString() + "/",
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: SUBMIT_EXAM_RSAA
});

export const getExam = (id) => createAction({
    endpoint: API_URL + 'pages/exam/' + id.toString() + "/",
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: GET_EXAM_RSAA
});

export const fetchSideMenu = (id) => createAction({
    endpoint: API_URL + 'pages/?child_of=' + id,
    method: 'GET',
    credentials: 'omit',
    headers: withAuth(),
    types: SIDE_MENU_GET_RSAA
});

export const fetchSideMenuBlock = (id) => createAction({
    endpoint: API_URL + 'pages/' + id + '/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth(),
    types: SIDE_MENU_BLOCK_GET_RSAA
});

export const resetMenu = () => ({
    type: MENU_RESET,
})

export const fetchMenu = () => createAction({
    endpoint: API_URL + 'pages/menu/',
    method: 'GET',
    headers: withAuth(),
    types: MENU_GET_RSAA
});