import {createAction} from 'redux-api-middleware';
import {API_URL} from '../constants';
import {LOGIN_RSAA, LOGOUT, SIGNUP_RSAA, RESET_PASSWORD_RSAA, TOKEN_RSAA, VERIFY_TOKEN_RSAA, TEMP_TOKEN_RSAA, CLEAR_AUTH, UPDATE_PROFILE_PICTURE} from '../constants/auth';
import {withAuth} from "../reducers";

export const login = (username, password) => createAction({
    endpoint: API_URL + 'auth/token/obtain/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify({username, password}),
    headers: {'Content-Type': 'application/json'},
    types: LOGIN_RSAA
});

export const register = (data) => createAction({
    endpoint: API_URL + 'users/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: {'Content-Type': 'application/json'},
    types: SIGNUP_RSAA
});

export const resetPassword = (data) => createAction({
    endpoint: API_URL + 'reset-password/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: {'Content-Type': 'application/json'},
    types: RESET_PASSWORD_RSAA
});

export const refreshAccessToken = (token) => createAction({
    endpoint: API_URL + 'auth/token/refresh/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify({refresh: token}),
    headers: {'Content-Type': 'application/json'},
    types: TOKEN_RSAA
});

export const verifyAccessToken = (token) => createAction({
    endpoint: API_URL + 'auth/token/verify/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify({token: token}),
    headers: {'Content-Type': 'application/json'},
    types: VERIFY_TOKEN_RSAA
});

export const getReadspeakerToken = () => createAction({
    endpoint: API_URL + 'auth/token/temporary/',
    method: 'POST',
    headers: withAuth(),
    types: TEMP_TOKEN_RSAA
});

export const logout = () => ({
    type: LOGOUT
});

export const clearAuth = () => ({
    type: CLEAR_AUTH,
});

export const updateProfilePicture = (path) => ({
    type: UPDATE_PROFILE_PICTURE,
    image_path: path
})