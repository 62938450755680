import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module'

import {Provider} from 'react-redux';
import StateLoader from "./localStorage"
import configureStore from './store';

if( process.env.NODE_ENV === 'production'){
    Sentry.init({dsn: "https://7c65d2ca61134d0ea967ee2759c02450@sentry.io/1476475"}); // TODO: Niels
}

// Load store and get auth state from localStorage
const stateLoader = new StateLoader();

let auth_state = {
    auth: {
        tokens: stateLoader.loadState()
    }
};

export const store = configureStore(auth_state);

store.subscribe(() => {
    stateLoader.saveState(store.getState().auth.tokens);
});

const tagManagerArgs = {
    gtmId: 'GTM-5JQ5WT6' // TODO: Niels
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();