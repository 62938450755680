export const LOGIN_REQUEST = '@@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@@auth/LOGIN_FAILURE';
export const LOGIN_RSAA = [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE];

export const SIGNUP_REQUEST = '@@auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = '@@auth/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = '@@auth/SIGNUP_FAILURE';
export const SIGNUP_RSAA = [SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE];

export const RESET_PASSWORD_REQUEST = '@@auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = '@@auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = '@@auth/RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_RSAA = [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE];

export const TOKEN_REQUEST = '@@auth/TOKEN_REQUEST';
export const TOKEN_RECEIVED = '@@auth/TOKEN_RECEIVED';
export const TOKEN_FAILURE = '@@auth/TOKEN_FAILURE';
export const TOKEN_RSAA = [TOKEN_REQUEST, TOKEN_RECEIVED, TOKEN_FAILURE];

export const VERIFY_TOKEN_REQUEST = '@@auth/VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_RECEIVED = '@@auth/VERIFY_TOKEN_RECEIVED';
export const VERIFY_TOKEN_FAILURE = '@@auth/VERIFY_TOKEN_FAILURE';
export const VERIFY_TOKEN_RSAA = [VERIFY_TOKEN_REQUEST, VERIFY_TOKEN_RECEIVED, VERIFY_TOKEN_FAILURE];

export const TEMP_TOKEN_REQUEST = '@@auth/TEMP_TOKEN_REQUEST';
export const TEMP_TOKEN_RECEIVED = '@@auth/TEMP_TOKEN_RECEIVED';
export const TEMP_TOKEN_FAILURE = '@@auth/TEMP_TOKEN_FAILURE';
export const TEMP_TOKEN_RSAA = [TEMP_TOKEN_REQUEST, TEMP_TOKEN_RECEIVED, TEMP_TOKEN_FAILURE];

export const CLEAR_AUTH = '@@auth/CLEAR_AUTH';

export const LOGOUT = '@@auth/LOGOUT';

export const UPDATE_PROFILE_PICTURE = '@@auth/UPDATE_PROFILE_PICTURE';