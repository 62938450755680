import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome/index';
import {Redirect} from "react-router-dom";
import {Selectbox} from "../Inc/Selectbox";

class SearchForm extends React.Component {

    state = {
        searchValue: '',
        searchType: '*',
        submit: false,
    }

    componentDidUpdate(prevProps, prevState) {

        if( this.props.show && !this.state.submit ){
            document.getElementById("s").focus();
            if( !(!!window.MSInputMethodContext && !!document.documentMode) ) {

                Selectbox();
            }
        }

        if( this.props.toggle_search_form !== false ){
            if( this.props.show && !this.state.submit ){
                document.querySelector('body').classList.add('has-search-form');
            } else {
                document.querySelector('body').classList.remove('has-search-form');
            }
        }

        if( this.state.submit ){
            this.setState({ submit: false });
        }
    }

    handleChange = (e) => {

        if(e.key === 'Enter') {

            this.onSubmit();
            return false;
        }

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({searchValue: value});

        return false;
    }

    onSubmit = (e) => {

        if( this.state.searchValue !== '' ){

            this.setState({submit: true});

            if( this.props.toggle_search_form !== false ){
                this.props.toggle_search_form();
            } else {
                document.querySelector(".menu-button").click();
            }
        }

        return false;
    };


    handleType = (e) => {
        let type = e.target.options[e.target.selectedIndex].value
        this.setState({ searchType: type });
    }

    render() {

        if( this.state.submit ){
            if( this.state.searchType === 'studenten' && this.props.is_coach ){
                return <Redirect to={'/studenten/scorebord/?q=' + this.state.searchValue } />
            } else {
                return <Redirect to={'/search/?q=' + this.state.searchValue + (this.state.searchType !== '*' && this.state.searchType !== '' ? '&type=' + this.state.searchType : '' ) } />
            }

        }

        return this.props.show ? (
            <div className={"search-form-container" + (this.props.is_coach ? ' with-options' : '')}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <form id="search-form" onSubmit={this.onSubmit}>
                                <input onKeyPress={this.handleChange} onChange={this.handleChange} value={this.state.searchValue} name="search" id="s" autoComplete="off" type="text" placeholder="Zoeken..." />
                                {this.props.is_coach ? (
                                    <div className="custom-select">
                                        <select name="type" defaultValue={this.state.searchType} onChange={this.handleType}>
                                            <option value="">Kies een optie</option>
                                            <option value="*">In alles</option>
                                            <option value="paginas">In pagina's</option>
                                            <option value="begrippen">In begrippen</option>
                                            <option value="studenten">In studenten</option>
                                        </select>
                                    </div>
                                ) : ''}
                                <button type="submit"><FontAwesomeIcon icon="search" /></button>
                            </form>
                            {this.props.toggle_search_form !== false ? <div className="close-search-form" onClick={this.props.toggle_search_form}><FontAwesomeIcon icon="times" /></div> : ''}
                        </div>
                    </div>
                </div>
            </div>
        ) : ''
    }
}

export default SearchForm;
