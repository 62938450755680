import {CONCEPTS_GET_SUCCESS} from "../constants/concept";
import {CONCEPTSSEARCH_GET_REQUEST, CONCEPTSSEARCH_GET_SUCCESS} from "../constants/concept";

const initialState = {
    list: undefined,
    letters: [],
    letter: {},
    errors: {},
};

const ConceptReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONCEPTS_GET_SUCCESS:
            return {
                list: action.payload.concepts,
                letters: action.payload.has_letters,
                letter: action.payload.letter,
            };
        case CONCEPTSSEARCH_GET_REQUEST:
            return {
                list: undefined,
            };
        case CONCEPTSSEARCH_GET_SUCCESS:
            return {
                list: action.payload.results,
            };
        default:
            return state
    }
}

export default ConceptReducer;