import {MEDIALIBRARY_GET_SUCCESS, MEDIALIBRARY_GET_FAILURE} from "../constants/medialibrary";

const initialState = {
    list: undefined,
    errors: {},
};

const MediaLibraryReducer = (state = initialState, action) => {
    switch (action.type) {
        case MEDIALIBRARY_GET_SUCCESS:
            return {
                list: action.payload.results,
            };
        case MEDIALIBRARY_GET_FAILURE:
            return {
                ...state,
                status: 'failure',
                errors:
                    action.payload.response ||
                    {'non_field_errors': action.payload.statusText},
            };
        default:
            return state
    }
}

export default MediaLibraryReducer;