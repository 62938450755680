import {VOUCHER_POST_FAILURE, VOUCHER_POST_REQUEST, VOUCHER_POST_SUCCESS} from "../constants/voucher";

const initialState = {
    list: undefined,
    errors: {},
    status: undefined,
};

const VoucherReducer = (state = initialState, action) => {
    switch (action.type) {
        case VOUCHER_POST_REQUEST:
            return {
                ...state,
                status: 'request',
            }
        case VOUCHER_POST_SUCCESS:
            return {
                ...state,
                errors: {},
                status: 'success',
            };
        case VOUCHER_POST_FAILURE:
            return {
                ...state,
                status: 'failure',
                errors:
                    action.payload.response ||
                    {'non_field_errors': action.payload.statusText},
            };
        default:
            return state
    }
}

export default VoucherReducer;