import React from "react";
import {Link, withRouter} from 'react-router-dom';
import default_user_picture from '../../static/images/default-user-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import {STATIC_URL} from "../../constants";
import SearchForm from "./SearchForm";

class Menu extends React.Component {
    
    state = {
        menu: this.props.menu,
    };

    componentDidMount() {
              
        // Nav (header)
        if( this.state.menu === 'header' ){

            var nav = document.querySelector("header#top nav");
            var navItems = nav.querySelectorAll("ul > li");
            
            for (var i = 0; i < navItems.length; i++) {
                
                navItems[i].addEventListener('mouseenter', function(event) {
                    
                    if( this.querySelectorAll("ul").length > 0 ){
                        this.querySelector("ul").style.display = "block";
                    } 
                });
                
                navItems[i].addEventListener('mouseleave', function(event) {
                    
                    if( this.querySelectorAll("ul").length > 0 ){
                        this.querySelector("ul").style.display = "none";
                    } 
                });
            } 
        }

        // MobileNav (off-canvas)
        if( this.state.menu === 'mobilenav' ){

            var navButton = document.querySelector(".menu-button");
            if( navButton != null ){
                
                navButton.addEventListener('click', function(){
                        
                    if( this.classList.contains('active') === true ){
                        
                        this.classList.remove("active");
                        document.documentElement.classList.remove("off-canvas");
                        document.querySelector("nav.off-canvas-nav .menu").style.display = "none";                            
                    } else {
                        var header_h = document.querySelector("header#top").offsetHeight;
                
                        this.classList.add("active");
                        document.documentElement.classList.add("off-canvas");
                        document.querySelector("nav.off-canvas-nav .menu").style.display = "flex";
                        document.querySelector("nav.off-canvas-nav .menu").style.marginTop = header_h + 'px';
                    }
                });
            }
            
            var offCanvasNav    = document.querySelector("nav.off-canvas-nav .menu");
            var toggleSubNavs   = offCanvasNav.querySelectorAll(".touch-button");
            
            for (i = 0; i < toggleSubNavs.length; i++) {
                
                toggleSubNavs[i].addEventListener('click', function(event) {
    
                    if( this.parentElement.parentElement.querySelectorAll("ul").length > 0 ){
                        if( this.parentElement.parentElement.querySelector("ul").style.display === 'block' ){
                            this.parentElement.parentElement.querySelector("ul").style.display = "none";
                        } else {
                            this.parentElement.parentElement.querySelector("ul").style.display = "block";
                        }
                    } 
                });
            }
            
            var handleClicks    = offCanvasNav.querySelectorAll("li a");
            for (i = 0; i < handleClicks.length; i++) {
                handleClicks[i].addEventListener('click', function(event) {
                    event = document.createEvent("Event");
                    event.initEvent("click", false, true); 
                    navButton.dispatchEvent(event);
                });
            }
        
        }

    }

    doLogout = () => {
        this.props.history.push('/')
        this.props.logout()
    }

    render() {

        let user_picture = default_user_picture;
        if( this.props.picture !== '' && this.props.picture.length > 0 ){
            user_picture = STATIC_URL + this.props.picture;
        }


        return (            
            <div className="menu">
                <ul>
                    <li className={"nav-item" + (this.props.location.pathname === this.props.current_course.url || this.props.location.pathname.startsWith('/voucher/') ? ' active': '')}>
                        <Link className="nav-link" to={this.props.current_course.url ? this.props.current_course.url : "/voucher/"}>
                            Start
                        </Link>
                    </li>

                    {this.props.is_coach ?
                        <li className={"nav-item" + (this.props.location.pathname.startsWith('/studenten/') ? ' active': '')}>
                            <Link className="nav-link" to='/studenten/'>
                                Studenten
                            </Link>
                        </li>
                    : ''}

                    <li className={"nav-item" + (this.props.location.pathname.startsWith('/opleiding/') ? ' active': '')}>
                        <Link className="nav-link" to='/opleiding/'>
                            Opleiding
                        </Link>
                    </li>

                    {this.props.current_course.has_concepts ?
                        <li className={"nav-item" + (this.props.location.pathname.startsWith('/begrippen/') ? ' active': '')}>
                            <Link className="nav-link" to={ "/begrippen/" + this.props.current_course.has_concepts.toLowerCase() + '/' }>
                                Begrippen
                            </Link>
                        </li>
                    : ''}

                    <li className={"nav-item" + (this.props.location.pathname === '/mediatheek/' ? ' active': '')}>
                        <Link className="nav-link" to='/mediatheek/'>
                            Mediatheek
                        </Link>
                    </li>

                    {this.state.menu === 'header' && (this.props.valid_voucher || this.props.is_coach) ?
                        <li className={"nav-item" + (this.props.location.pathname.startsWith('/search/') ? ' active': '')}>
                            <Link className="nav-link" to='#' onClick={this.props.toggle_search_form}>
                                <FontAwesomeIcon icon="search" />
                            </Link>
                        </li>
                    : ''}
                    
                    <li className={"nav-item" + (this.props.location.pathname === '/help/' ? ' active': '')}>
                        <Link className="nav-link" to='/help/'>
                            <FontAwesomeIcon icon="info-circle" />
                        </Link>
                    </li>
                    
                    <li className="nav-item nav-user">
                        <Link className="nav-link" to={!this.props.valid_voucher ? '#' : '/profile/'}>
                            <div className="user-photo">
                                <img src={user_picture} alt={this.props.name} />
                                <FontAwesomeIcon icon="caret-down" />
                            </div>
                            <div className="user-name">
                                <span className="name">{this.props.name}</span>
                                <span className="role">{this.props.is_coach ? 'Opleider' : 'Student'}</span>
                            </div>
                        </Link>
                        <div className="touch-button"><FontAwesomeIcon icon="caret-down" /></div>
                        <ul>
                            {this.props.valid_voucher || this.props.is_coach ?
                                (<li className={this.props.location.pathname === '/profile/' ? 'active': ''}>
                                    <Link to='/profile/'>Mijn profielinformatie</Link>
                                </li>)
                            : ''
                            }
                            <li>
                                <button onClick={ this.doLogout }>Uitloggen</button>
                            </li>
                        </ul>
                    </li>
                    {this.state.menu === 'mobilenav' ? (
                        <li>
                            <SearchForm show={true} toggle_search_form={false} is_coach={this.props.is_coach} />
                        </li>
                    ) : ''}
                </ul>
            </div>
        )

    }
}

export default withRouter(Menu);
